import React, { useState } from "react";
import { DatePicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { Moment } from "moment";

type RangeValue = [Moment | null, Moment | null] | null;

const DateRangePicker = ({
  onChange,
  value,
  clearable = false,
  disabledDate,
  allowEmpty = [false, false],
}: {
  value?;
  onChange?: (value) => any;
  clearable?: boolean;
  disabledDate?: any;
  allowEmpty?: [boolean, boolean];
}) => {
  const [dateRangeValue, setDateRangeValue] = useState<RangeValue>([
    value?.from,
    value?.to,
  ]);

  return (
    <DatePicker.RangePicker
      value={dateRangeValue || null}
      onChange={(dates) => {
        setDateRangeValue([dates[0], dates[1]]);
        onChange(dates);
      }}
      onCalendarChange={(val) => setDateRangeValue(val)}
      allowClear={clearable}
      separator={<CalendarOutlined />}
      suffixIcon={null}
      className="date-range-picker"
      allowEmpty={allowEmpty}
    />
  );
};

export default DateRangePicker;
