import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CurrencyLabel } from "../../../Common/components";
import { _t } from "../../../Common/components/InjectIntlContext";
import { useNullingLogs } from "../../apiHooks";
import { NoData } from "../../../Common/components/NoData";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("period"),
    dataIndex: "period",
    key: "period",
  },
  {
    title: _t("amount"),
    dataIndex: "amount",
    key: "amount",
    render: (val) => <CurrencyLabel value={val} />,
  },
];

const useTableData = (id) => {
  const { isLoading, data } = useNullingLogs({
    user_id: id,
  });
  return { isLoading, data };
};

export function NullingWidget({ user }) {
  const { data, isLoading } = useTableData(user?.id);
  const translatedColumns = columns(_t);
  return (
    <>
      <Table
        loading={isLoading}
        columns={translatedColumns}
        rowKey="id"
        dataSource={data?.data}
        pagination={false}
        locale={{ emptyText: <NoData /> }}
      />
      <br />
    </>
  );
}
