import React from "react";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import ReturnAdmin from "./special/ReturnAdmin";

const MainLayout = (props) => {
  const { headerLinks, withoutHeader } = props;
  return (
    <div className="inner-wrapper">
      <Sidebar />

      <ReturnAdmin />
      <div className="innner-wrapper-content">
        {!withoutHeader && <Header links={headerLinks} />}
        <div className="main-container">{props.children}</div>
      </div>
    </div>
  );
};

MainLayout.defaultProps = {
  withoutHeader: false,
  headerLinks: [],
};

export default MainLayout;
