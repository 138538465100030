import React from "react";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import {_t} from "../../../Common/components/InjectIntlContext";
import {useGamerMultiples} from "../../apiHooks";
import {Link} from "react-router-dom";
import {NoData} from "../../../Common/components/NoData";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("partner"),
    dataIndex: "partner_id",
    key: "partner_id",
    render: (val) => <Link to={"/users/profile/" + val}>{val}</Link>,
  },
  {
    title: _t("Gamer ID"),
    dataIndex: "gamer_id",
    key: "gamer_id",
  },
  {
    title: _t("IP"),
    dataIndex: "ip",
    key: "ip",
  },
];

const useTableData = (id) => {
  const {isLoading, data} = useGamerMultiples({
    user_id: id,
  });
  return {isLoading, data};
};

export function GamerMultiplesWidget({user}) {
  const {data, isLoading} = useTableData(user?.id);
  const translatedColumns = columns(_t);
  return (
    <>
      <Table
        loading={isLoading}
        columns={translatedColumns}
        rowKey="date"
        dataSource={data?.data}
        pagination={false}
        locale={{ emptyText: <NoData /> }}
      />
      <br/>
    </>
  );
}
