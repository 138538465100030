import React, { useState } from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import classNames from "classnames";
import image_1 from "../../../assets/images/outer/how_it_work_1.png";
import image_2 from "../../../assets/images/outer/how_it_work_2.png";
import image_3 from "../../../assets/images/outer/how_it_work_3.png";
import image_4 from "../../../assets/images/outer/how_it_work_4.png";

const buttons = (_t: any) => [
  {
    title: _t("how_1"),
  },
  {
    title: _t("how_2"),
  },
  {
    title: _t("how_3"),
  },
  {
    title: _t("how_4"),
  },
];

const HowItWorks = () => {
  const [selected, setSelected] = useState(0);
  const howItWorksButtons = buttons(_t);
  const images = [image_1, image_2, image_3, image_4];
  return (
    <section className="how-it-works custom-section">
      <div className="container">
        <div className="how-it-works__container">
          <div className="how-it-works__image">
            <img src={images[selected]} alt="" />
          </div>
          <div className="how-it-works__info">
            <h2 className="how-it-works__title">{_t("how_it_works")}</h2>
            <ul className="how-it-works__buttons">
              {howItWorksButtons.map((item, idx) => (
                <li key={item.title}>
                  <button
                    className={classNames({ active: selected === idx })}
                    onClick={() => setSelected(idx)}
                  >
                    {item.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
