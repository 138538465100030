import React from "react";
import AddOperationForm from "./AddOperationForm";
import {useAddOperation} from "../apiHooks";


export function AddOperationWidget({user}) {
  const [addOperation, { isLoading }] = useAddOperation();
  const handleSubmit = (values) => {
    addOperation({
      ...values,
      partner_id: user.id,
    });
  }
  return (
    <>
      <AddOperationForm loading={isLoading} onFinish={handleSubmit} user={user} />
    </>
  );
}
