import React from "react";
import {Table, Tag} from "antd";
import {ColumnsType} from "antd/lib/table";
import {CurrencyLabel} from "../../../Common/components";
import {_t} from "../../../Common/components/InjectIntlContext";
import {useBonuses} from "../../apiHooks";
import {NoData} from "../../../Common/components/NoData";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("Date"),
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: _t("Type"),
    dataIndex: "type",
    key: "type",
    render: (val, r) => <span>{val} {r.visible ? "" : <Tag color="default">{_t("invisible")}</Tag>}</span>,
  },
  {
    title: _t("amount"),
    dataIndex: "amount",
    key: "amount",
    render: (val) => <CurrencyLabel value={val}/>,
  },
  {
    title: _t("comment"),
    dataIndex: "comment",
    key: "comment",
  },
];

const useTableData = (id) => {
  const {isLoading, data} = useBonuses({
    user_id: id,
  });
  return {isLoading, data};
};

export function BonusesWidget({user}) {
  const {data, isLoading} = useTableData(user?.id);
  const translatedColumns = columns(_t);
  return (
    <>
      <Table
        loading={isLoading}
        columns={translatedColumns}
        rowKey="id"
        dataSource={data?.data}
        pagination={false}
        locale={{ emptyText: <NoData /> }}
      />
      <br/>
    </>
  );
}
