import React from "react";
import { FaqResponse } from "../../api";
import { Faqs } from "../Faqs/Faqs";
import { FaqTitle } from "../FaqTitle/FaqTitle";
import { _t } from "../../../Common/components/InjectIntlContext";
import Faq1Img from "../../../../images/cards/FaqCards/card1.png";
import Faq2Img from "../../../../images/cards/FaqCards/card2.png";
import Faq3Img from "../../../../images/cards/FaqCards/card3.png";
import {
  FAQ_TITLE_FINANCE,
  FAQ_TITLE_GENERAL,
  FAQ_TITLE_TECHNICAL,
} from "../../constants";

type FaqTitlesProps = {
  data: FaqResponse;
  loading: boolean;
  setFilter: (v) => void;
};

export function FaqTitles({ data, loading, setFilter }: FaqTitlesProps) {
  const tiles = (_t: any) => [
    {
      title: _t("general"),
      tileClass: "general",
      selected: data?.current_category === FAQ_TITLE_GENERAL ? "selected" : "",
      requestParam: FAQ_TITLE_GENERAL,
      image: Faq1Img,
    },
    {
      title: _t("financial"),
      tileClass: "financial",
      selected: data?.current_category === FAQ_TITLE_FINANCE ? "selected" : "",
      requestParam: FAQ_TITLE_FINANCE,
      image: Faq2Img,
    },
    {
      title: _t("technical"),
      tileClass: "technical",
      selected:
        data?.current_category === FAQ_TITLE_TECHNICAL ? "selected" : "",
      requestParam: FAQ_TITLE_TECHNICAL,
      image: Faq3Img,
    },
  ];
  const tilesTranslated = tiles(_t);

  return (
    <>
      <section className="custom-section custom-section--no-indent faq">
        <div className="container">
          <h2 className="custom-section__title">{_t("FAQ")}</h2>
          <div className="faq-nav">
            {tilesTranslated.map((item) => (
              <FaqTitle
                key={item.tileClass}
                data={data}
                requestParam={item.requestParam}
                loading={loading}
                title={item.title}
                setFilter={setFilter}
                tileClass={item.tileClass}
                selected={item.selected}
                image={item.image}
              />
            ))}
          </div>
          <Faqs data={data} loading={loading} />
        </div>
      </section>
    </>
  );
}
