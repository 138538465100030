import React, { useState } from "react";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import logo from "../../../images/icons/sidebar/logo.svg";
import { UserProfilePreview } from "../../../pages/inner/components";
import { NAVIGATION_SCHEME } from "./constants";
import { _t } from "../../Common/components/InjectIntlContext";
import button from "../../Common/components/Button";
import SidebarNavigationItem from "./components/SidebarNavigationItem/SidebarNavigationItem";
import LandingLanguages from "../../Landing/components/LandingLanguages";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <UserProfilePreview />
      <ul className="sidebar-navigation">
        {NAVIGATION_SCHEME(_t).map((navigationItem) => {
          if (navigationItem.permission) {
            return navigationItem.permission.includes(
              localStorage.getItem("role")
            ) ? (
              <li key={navigationItem.title}>
                <SidebarNavigationItem navigationItem={navigationItem} />
              </li>
            ) : null;
          }
          return (
            <li key={navigationItem.title}>
              <SidebarNavigationItem navigationItem={navigationItem} />
            </li>
          );
        })}
      </ul>
      <LandingLanguages />

      <Link to={APP_ROUTES.dashboard.general} className="sidebar-logo">
        <img src={logo} alt="" />
      </Link>
    </div>
  );
};
export default Sidebar;
