import React from "react";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { _t } from "../../Common/components/InjectIntlContext";
import CommissionCard from "./CommissionCard";
import image from "../../../assets/images/outer/how-it-works.png";

const commissions = (_t: any) => [
  {
    title: _t("revshare_commission"),
    linkTo: APP_ROUTES.commissions.revshare,
    text: _t("revshare_commission_desc"),
  },
  {
    title: _t("cpa_program"),
    linkTo: APP_ROUTES.commissions.cpa,
    text: _t("cpa_program_desc"),
  },
  {
    title: _t("subaffiliate_program"),
    linkTo: APP_ROUTES.commissions.subaffiliate,
    text: _t("subaffiliate_program_desc"),
  },
  {
    title: _t("hybrid_program"),
    linkTo: APP_ROUTES.commissions.hybrid,
    text: _t("hybrid_program_desc"),
  },
];

const Commissions = () => {
  const commissionsTranslated = commissions(_t);
  const commissionItem = (card, idx) => (
    <CommissionCard info={card} key={card.title + idx} />
  );
  return (
    <section className="custom-section commissions">
      <div className="container">
        <div className="commission-page-wrap">
          <div className="commission-page-wrap__image">
            <img src={image} alt="" />
          </div>
          <h2 className="commission-page-wrap__title">{_t("Commissions")}</h2>
          <div className="commissions-grid">
            {commissionsTranslated.map(commissionItem)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Commissions;
