import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import arrow from "../../../../../assets/images/arrow-drop-menu.svg";

const SidebarNavigationItem = ({ navigationItem }: any) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      {!navigationItem.subMenu ? (
        <NavLink to={navigationItem.path}>{navigationItem.title}</NavLink>
      ) : (
        <button onClick={() => setIsOpened(!isOpened)}>
          {navigationItem.title}
          <span>
            <img src={arrow} alt="" />
          </span>
        </button>
      )}

      {isOpened && navigationItem.subMenu && (
        <ul className="sidebar-sub-menu">
          {navigationItem.subMenu?.map((subItem) => {
            if (subItem.permission) {
              return subItem.permission.includes(
                localStorage.getItem("role")
              ) ? (
                <li key={subItem.title}>
                  <NavLink to={subItem.path}>{subItem.title}</NavLink>
                </li>
              ) : null;
            }

            return (
              <li key={subItem.title}>
                <NavLink to={subItem.path}>{subItem.title}</NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default SidebarNavigationItem;
