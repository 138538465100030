import React from "react";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import {CurrencyLabel} from "../../../Common/components";
import {_t} from "../../../Common/components/InjectIntlContext";
import {useAccruals} from "../../apiHooks";
import {NoData} from "../../../Common/components/NoData";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("accounting_period"),
    dataIndex: "start_date",
    key: "start_date",
    render: (val, r) => <span>{val} - {r.end_date}</span>,
  },
  {
    title: _t("amount"),
    dataIndex: "amount",
    key: "amount",
    render: (val) => <CurrencyLabel value={val}/>,
  },
];

const useTableData = (id) => {
  const {isLoading, data} = useAccruals({
    user_id: id,
  });
  return {isLoading, data};
};

export function AccrualsWidget({user}) {
  const {data, isLoading} = useTableData(user?.id);
  const translatedColumns = columns(_t);
  return (
    <>
      <Table
        loading={isLoading}
        columns={translatedColumns}
        rowKey="start_date"
        dataSource={data?.data}
        pagination={false}
        locale={{ emptyText: <NoData /> }}
      />
      <br/>
    </>
  );
}
