import React from "react";
import { Table } from "antd";
import { RenderSubtotalsCell } from "../RenderSubtotalsCell";
type RenderSubtotalsInterface = {
  translatedColumns: any;
  subTotals: any;
  convertSymbol?: string | undefined;
};
const RenderSubtotals = ({ translatedColumns, subTotals }) => {
  return (
    <Table.Summary.Row className={"report-table-subtotals"}>
      {translatedColumns.map((column, key) => (
        <Table.Summary.Cell index={key} key={key}>
          {column.dataIndex in subTotals ? (
            <RenderSubtotalsCell
              column={column.dataIndex}
              value={subTotals[column.dataIndex]}
            />
          ) : (
            <></>
          )}
        </Table.Summary.Cell>
      ))}
    </Table.Summary.Row>
  );
};

export default RenderSubtotals;
