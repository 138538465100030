import React from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import ReCAPTCHA from "react-google-recaptcha";
import image from "../../../../assets/images/outer/contact-image.png";
import { useSendContacts } from "./apiHooks";
import { getLocale } from "../../../../system/helpers/localstorageHelper";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { _t } from "../../../Common/components/InjectIntlContext";
import config from "../../../../system/config";

const Contacts = () => {
  const [SendContacts, { isLoading }] = useSendContacts();
  const recaptchaRef = React.createRef();
  const locale = getLocale();
  return (
    <div className="" id="contacts">
      <div className="container">
        <div className="contact-section">
          <Form
            action={APP_ROUTES.contacts}
            className="contact-section-form"
            method="post"
            onFinish={SendContacts}
          >
            <div className="contact-section-form__info">
              <h2>{_t("get_in_touch")}</h2>
              <p>{_t("contacts_welcome_message")}</p>
            </div>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: _t("input_name"),
                },
              ]}
            >
              <Input placeholder={_t("Name")} />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: _t("input_email"),
                },
                {
                  type: "email",
                  message: _t("not_valid_email_message"),
                },
              ]}
            >
              <Input placeholder={_t("Email")} />
            </Form.Item>

            <Form.Item
              name="text"
              className="textarea-wrap"
              rules={[
                {
                  required: true,
                  message: _t("Please input details"),
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder={_t("your_message")} />
            </Form.Item>
            <Form.Item
              name="g-recaptcha-response"
              rules={[
                {
                  required: true,
                  message: _t("recaptcha_required"),
                },
              ]}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config.google_recaptcha_sitekey}
                className={"recaptcha"}
                hl={locale}
              />
            </Form.Item>
            <button
              className="btn btn--black"
              type="submit"
              disabled={isLoading}
            >
              {_t("Send")}
            </button>
          </Form>
          <div className="contact-section__image">
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
