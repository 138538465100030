import React from "react";
import {Card} from "antd";
import {Tabs} from "antd";
import {_t} from "../../Common/components/InjectIntlContext";
import {AccrualsWidget} from "./tables/AccrualsWidget";
import {PayoutsWidget} from "./tables/PayoutsWidget";
import {AuthLogWidget} from "./tables/AuthLogWidget";
import {MultiplesWidget} from "./tables/MultiplesWidget";
import {GamerMultiplesWidget} from "./tables/GamerMultiplesWidget";
import {BonusesWidget} from "./tables/BonusesWidget";
import {WalletLogsWidget} from "./tables/WalletLogsWidget";
import {StatusLogsWidget} from "./tables/StatusLogsWidget";
import {RoleLogsWidget} from "./tables/RoleLogsWidget";
import {NullingWidget} from "./tables/NullingWidget";
import {AddOperationWidget} from "./AddOperationWidget";

const {TabPane} = Tabs;

export const DetailsTabsWidget = ({user}) => {
  return (
    <Card>
      <Tabs defaultActiveKey="1">
        <TabPane tab={_t("add_operation")} key="1">
          <AddOperationWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("Accruals")} key="2">
          <AccrualsWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("payouts")} key="3">
          <PayoutsWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("Authentication log")} key="4">
          <AuthLogWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("ip_multiples")} key="5">
          <MultiplesWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("gamer_ip_multiples")} key="6">
          <GamerMultiplesWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("bonuses_fines")} key="7">
          <BonusesWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("payments_data_log")} key="8">
          <WalletLogsWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("status_changing_log")} key="9">
          <StatusLogsWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("role_changing_log")} key="10">
          <RoleLogsWidget user={user}/>
        </TabPane>
        <TabPane tab={_t("negative_compensation")} key="11">
          <NullingWidget user={user}/>
        </TabPane>
      </Tabs>
    </Card>
  );
}
