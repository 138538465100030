import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AdminMails, UserMails } from "./index";
import logoutIcon from "../../../../assets/images/logout.svg";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { removeItem } from "../../../../system/helpers/localstorageHelper";
import { logoutAction } from "../../../../modules/Auth/actions";

const UserProfilePreview = () => {
  const userInfo = useSelector((state) => state?.user?.basicInfo);
  const isAdmin = localStorage.getItem("role") === "admin";
  const dispatch = useDispatch();
  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(logoutAction());
    removeItem("isShowNotifications");
  };

  return (
    <div className="user-profile-preview">
      {isAdmin ? <AdminMails /> : <UserMails />}
      {!userInfo.isLoading && (
        <>
          <Link to={APP_ROUTES.user.main} className="user-profile-link">
            {userInfo.login} <span>ID {userInfo.id}</span>
          </Link>
          <button className="logout-button" onClick={handleLogout}>
            <img src={logoutIcon} alt="" />
          </button>
        </>
      )}
    </div>
  );
};

export default UserProfilePreview;
