import React from "react";
import { Report, GamerStatsItem } from "./api";
import { ColumnType } from "antd/lib/table";
import { Typography } from "antd";
import { CurrencyLabel } from "../Common/components";
import config from "../../system/config";
import { _t } from "../Common/components/InjectIntlContext";
import { CellPartnerIncome } from "./components/Totals/components";

export type ColumnOption = {
  label: string;
  value: keyof Report;
  hidden?: boolean;
};

export const dateKey = "filters.date";
export const visibleColumnsKey = "visibleColumns";

export const periodOptions = (_t) => [
  { label: _t("day"), value: "day" },
  { label: _t("week"), value: "week" },
  { label: _t("month"), value: "month" },
];
export const columnOptions: Array<ColumnOption> = [
  {
    label: "Hits",
    value: "hits",
  },
  {
    label: "Hosts",
    value: "hosts",
  },
  {
    label: "Reg.",
    value: "regs",
  },
  {
    label: "Deposits",
    value: "deposits",
  },
  // {
  //   label: "Withdraws",
  //   value: "withdraws",
  // },
  {
    label: "Net Gaming",
    value: "ng",
  },
  {
    label: "Casino Promo",
    value: "comppoints",
  },
  // {
  //   label: "Full inc",
  //   value: "casino_income",
  // },
  {
    label: "Gamers",
    value: "gamers",
    hidden: true,
  },
  {
    label: "Rounds",
    value: "rounds",
    hidden: true,
  },
  {
    label: "1st depositors",
    value: "first_deposits",
  },
  {
    label: "Qualified players",
    value: "qualified_players",
  },
  {
    label: "Multi accounts",
    value: "mults",
    hidden: true,
  },
];

export const lineOptions = (_t: any) => [
  { value: "hits", label: _t("Hits") },
  { value: "hosts", label: _t("Hosts") },
  { value: "regs", label: _t("regs") },
  { value: "conversion", label: _t("conversion") },
  { value: "gamers", label: _t("Gamers") },
  { value: "first_deposits", label: _t("first_deposits") },
  { value: "deposits", label: _t("deposits") + ", " + config.projectCurrency },
  // {
  //   value: "withdraws",
  //   label: _t("Withdraws") + ", " + config.projectCurrency,
  // },
  { value: "ng", label: _t("Net Gaming") + ", " + config.projectCurrency },
  { value: "comppoints", label: _t("bonus") + ", " + config.projectCurrency },
  { value: "qualified_players", label: _t("Qualified players") },
  // {
  //   value: "full_income",
  //   label: _t("Full income") + ", " + config.projectCurrency,
  // },
  // {
  //   value: "partner_income",
  //   label: _t("Partner income") + ", " + config.projectCurrency,
  // },
  // {
  //   value: "full_assured_income",
  //   label: _t("assured_income") + ", " + config.projectCurrency,
  // },
];

export const lineOptionsValues = [
  { value: "hits" },
  { value: "hosts" },
  { value: "regs" },
  { value: "conversion" },
  { value: "gamers" },
  { value: "first_deposits" },
  { value: "deposits" },
  // { value: "withdraws" },
  { value: "ng" },
  { value: "comppoints", hidden: true },
  { value: "qualified_players" },
  { value: "full_income" },
  { value: "partner_income" },
  { value: "full_assured_income", hidden: true },
];

export const currencyFields = [
  "withdraws",
  "deposits",
  "ng",
  "bonus",
  "full_income",
  "comppoints",
  "partner_income",
  "full_assured_income",
  "net_gaming",
  "ltv",
  "full_profit",
  "balance",
  "casino_profit",
  "payouts",
  "first_deposit",
  "deposits_sum",
  "net_gaming",
  "bonuses",
  "first_deposit",
  "deposits_sum",
  "net_gaming",
  "bonuses",
  "bets",
  "casino_income",
  "assured_income",
  "hold",
  "in_out1",
  "in_out2",
  "in_out_diff",
  "in_out_rel_diff",
  "ngcp1",
  "ngcp2",
  "ngcp_diff",
  "ngcp_rel_diff",
  "partner_income1",
  "partner_income2",
  "partner_income_diff",
  "partner_income_rel_diff",
  "ngr",
  "ngr1",
  "ngr2",
  "ggr",
  "ggr1",
  "ggr2",
  "royalty",
  "royalty1",
  "royalty2",
  "transaction_commission",
  "transaction_commission1",
  "transaction_commission2",
  "rs_income",
  "rs_income1",
  "rs_income2",
  "assured_income1",
  "assured_income2",
];

const currencyFieldsSet = new Set(currencyFields);
export const isCurrencyField = (name) => currencyFieldsSet.has(name);
export const renderCurrency = (value) => <CurrencyLabel value={value} />;

export const subtotalColumns = [
  "hits",
  "hosts",
  "regs",
  "withdraws",
  "deposits",
  "ng",
  "bonus",
  "gamers",
  "rounds",
  "full_income",
  "comppoints",
  "partner_income",
  "net_gaming",
  "ltv",
  "full_profit",
  "first_deposits",
  "qualified_players",
  "landing_hits",
  "landing_hosts",
  "mults",
  "first_deposits1",
  "first_deposits2",
  "first_deposits_diff",
  "first_deposits_rel_diff",
  "in_out1",
  "in_out2",
  "in_out_diff",
  "in_out_rel_diff",
  "ngcp1",
  "ngcp2",
  "ngcp_diff",
  "ngcp_rel_diff",
  "partner_income1",
  "partner_income2",
  "partner_income_diff",
  "partner_income_rel_diff",
];

const allColumnsMap: Record<
  | keyof Omit<Report, "cancels" | "hold" | "casino_income" | "bonus">
  | "program"
  | "gamers_program"
  | "promo"
  | "tracker"
  | "landing_title"
  | "landing_hits"
  | "landing_hosts"
  | "click_id"
  | "sub_id2"
  | "sub_id"
  | "gamer_id"
  | "reg_date"
  | "reg_ip"
  | "country"
  | "city"
  | "bets"
  | "first_deposit_date"
  | "dep_transactions"
  | "full_profit"
  | "full_income"
  | "mults",
  ColumnType<any>
> = {
  date: {
    dataIndex: "date",
    title: "Date",
    render: (value) => {
      return (
        <Typography.Text style={{ whiteSpace: "nowrap" }} type="warning">
          {value}
        </Typography.Text>
      );
    },
  },
  hits: {
    title: "Hits",
    dataIndex: "hits",
  },
  hosts: {
    title: "Hosts",
    dataIndex: "hosts",
  },
  installs: {
    title: "Installs",
    dataIndex: "installs",
  },
  regs: {
    title: "Reg.",
    dataIndex: "regs",
  },
  regs_all: {
    title: "Reg.All",
    dataIndex: "regs_all",
  },
  gamers: {
    title: "Gamers",
    dataIndex: "gamers",
  },
  rounds: {
    title: "Rounds",
    dataIndex: "rounds",
  },
  first_deposits: {
    title: "1st depositors",
    dataIndex: "first_deposits",
  },
  first_deposits_amount: {
    title: "1st depositsamount, ₺",
    dataIndex: "first_deposits_amount",
    render: renderCurrency,
  },
  deposits_count: {
    title: "Deposits count",
    dataIndex: "deposits_count",
  },
  // deposits_count: { todo check
  //   title: 'Depositors',
  //   dataIndex: 'deposits_count'
  // }
  deposits: {
    title: "Deposits, ₺",
    dataIndex: "deposits",
    render: renderCurrency,
  },
  withdraws: {
    title: "Withdraws, ₺",
    dataIndex: "withdraws",
    render: renderCurrency,
  },
  qualified_players: {
    title: "Qualified players",
    dataIndex: "qualified_players",
  },
  ng: {
    title: "Net Gaming, ₺",
    dataIndex: "ng",
    render: renderCurrency,
  },
  full_income: {
    title: "Full income, ₺",
    dataIndex: "full_income",
    render: renderCurrency,
  },
  partner_income: {
    title: "Partner inc, ₺",
    dataIndex: "partner_income",
    render: (v, r) => <CellPartnerIncome {...r} tableDetails />,
  },
  comppoints: {
    title: "Bonus, ₺",
    dataIndex: "comppoints",
    render: renderCurrency,
  },
  bets: {
    title: "Bets",
    dataIndex: "bets",
    render: renderCurrency,
  },
  project: {
    title: "Project",
    dataIndex: "project",
    render: (v) => v?.name,
  },
  program: {
    title: "Program",
    dataIndex: "program",
    render: (v, r) => `${r?.project?.name} / ${v?.name}`,
  },
  gamers_program: {
    title: "Program",
    dataIndex: "program",
    render: (v, r) => `${r?.project?.name} / ${v}`,
  },
  promo: {
    title: "Promo",
    dataIndex: "promo",
    render: (v) => v?.name || v?.code,
  },
  tracker: {
    title: "Tracker",
    dataIndex: "tracker",
    render: (v) => v?.name,
  },
  landing_title: {
    title: "Landing page",
    dataIndex: "landing_title",
  },
  landing_hits: {
    title: "LP hits",
    dataIndex: "landing_hits",
  },
  landing_hosts: {
    title: "LP hosts",
    dataIndex: "landing_hosts",
  },
  sub_id2: {
    title: "Sub Id 2",
    dataIndex: "sub_id2",
  },
  sub_id: {
    title: "Sub Id",
    dataIndex: "sub_id",
    render: (v) => <div style={{ wordBreak: "break-all" }}>{v}</div>,
  },
  gamer_id: {
    title: "Gamer Id",
    dataIndex: "gamer_id",
  },
  click_id: {
    title: "Click Id",
    dataIndex: "click_id",
  },
  reg_date: {
    title: "Reg Date",
    dataIndex: "reg_date",
  },
  reg_ip: {
    title: "Reg ip",
    dataIndex: "reg_ip",
  },
  country: {
    title: "Country",
    dataIndex: "country",
  },
  city: {
    title: "City",
    dataIndex: "city",
  },
  first_deposit_date: {
    title: "First deposit date",
    dataIndex: "first_deposit_date",
  },
  dep_transactions: {
    title: "Dep. transactions count",
    dataIndex: "dep_transactions",
  },
  full_profit: {
    title: "Full profit",
    dataIndex: "full_profit",
    render: renderCurrency,
  },
  mults: {
    title: "multi_account",
    dataIndex: "mults",
  },
};

export const pickColumns = (keys: Array<keyof typeof allColumnsMap>) =>
  keys.map((key) => allColumnsMap[key]);

export const allGeneralPageColumns: ColumnType<Report>[] = pickColumns([
  "date",
  "hits",
  "hosts",
  "regs",
  "first_deposits",
  "gamers",
  "rounds",
  "deposits",
  // "withdraws",
  "qualified_players",
  "ng",
  "comppoints",
  // "full_income",
  "partner_income",
  "mults",
]);
export const allCommissionsColumns = pickColumns([
  "date",
  "program",
  "hits",
  "hosts",
  "regs",
  "first_deposits",
  "gamers",
  "deposits",
  // "withdraws",
  "qualified_players",
  "ng",
  "comppoints",
  // "full_income",
  "partner_income",
  "mults",
]);

export const allPromoColumns = pickColumns([
  "date",
  "promo",
  "project",
  "hits",
  "hosts",
  "regs",
  "first_deposits",
  "gamers",
  "rounds",
  "deposits",
  // "withdraws",
  "qualified_players",
  "ng",
  "comppoints",
  // "full_income",
  "partner_income",
  "mults",
]);

export const allTrackerColumns = pickColumns([
  "date",
  "tracker",
  "hits",
  "hosts",
  "regs",
  "first_deposits",
  "gamers",
  "rounds",
  "deposits",
  // "withdraws",
  "qualified_players",
  "ng",
  "comppoints",
  // "full_income",
  "partner_income",
  "mults",
]);

export const allLandingsColumns = pickColumns([
  "date",
  "landing_title",
  "landing_hits",
  "landing_hosts",
  "hits",
  "hosts",
  "regs",
  "first_deposits",
  "gamers",
  "rounds",
  "deposits",
  // "withdraws",
  "qualified_players",
  "ng",
  "comppoints",
  // "full_income",
  "partner_income",
  "mults",
]);

export const allSubIdColumns = pickColumns([
  "date",
  "sub_id2",
  "project",
  "hits",
  "hosts",
  "regs",
  "first_deposits",
  "gamers",
  "rounds",
  "deposits",
  // "withdraws",
  "qualified_players",
  "ng",
  "comppoints",
  // "full_income",
  "partner_income",
  "mults",
]);

export const allClickIdColumns = pickColumns([
  "date",
  "sub_id",
  "project",
  "hits",
  "hosts",
  "regs",
  "first_deposits",
  "gamers",
  "rounds",
  "deposits",
  // "withdraws",
  "qualified_players",
  "ng",
  "comppoints",
  // "full_income",
  "partner_income",
  "mults",
]);

export const gamerProfileColumns: ColumnType<GamerStatsItem>[] = pickColumns([
  "date",
  "rounds",
  "ng",
  "deposits",
  // "withdraws",
  "comppoints",
  "partner_income",
]);

export const gamersColumns = pickColumns([
  "gamer_id",
  "project",
  "gamers_program",
  "reg_date",
  "reg_ip",
  "country",
  "city",
  "deposits",
  // "withdraws",
  "ng",
  "comppoints",
  // "full_profit",
  "partner_income",
]);

export const isoCountries = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, Democratic Republic",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Cote D'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island & Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic Of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle Of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States Of",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena",
  KN: "Saint Kitts And Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre And Miquelon",
  VC: "Saint Vincent And Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome And Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia And Sandwich Isl.",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks And Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UM: "United States Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis And Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};
export const API_GLOBAL_NOTIFICATIONS = {
  key: "API_KEY_GET_GLOBAL_NOTIFICATIONS",
  path: "api/notifications/global-notifications",
};
