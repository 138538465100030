import React from "react";
import {Card, List, Typography} from "antd";
import {_t} from "../../Common/components/InjectIntlContext";

const {Text} = Typography;
type LastStatsView = {
  lastStatsView: any;
}

export const StatsViewWidget = ({lastStatsView}: LastStatsView) => {
  return (
    <Card title={_t("last_stats_view")}>
      <List size="small">
        <List.Item>
          <Text strong>{lastStatsView?.ip}</Text> - {lastStatsView?.created_at}
        </List.Item>
      </List>
    </Card>
  );
};
