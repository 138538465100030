import React from "react";
import {Table, Tag} from "antd";
import {ColumnsType} from "antd/lib/table";
import {_t} from "../../../Common/components/InjectIntlContext";
import {useRoleLogs} from "../../apiHooks";
import {CaretDownOutlined} from '@ant-design/icons';
import {NoData} from "../../../Common/components/NoData";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("changed_by"),
    dataIndex: "editor_login",
    key: "editor_login",
  },
  {
    title: _t("IP"),
    dataIndex: "editor_ip",
    key: "editor_ip",
  },
  {
    title: _t("Date"),
    dataIndex: "date",
    key: "date",
  },
  {
    title: _t("Data"),
    dataIndex: "data_json_parsed",
    key: "data_json_parsed",
    render: (val) => {
      return (
        <div style={{textAlign: "center"}}>
          <Tag color="volcano">
            <div dangerouslySetInnerHTML={{__html: (val.from ? val.from : _t("undefined"))}}></div>
          </Tag>
          <div>
            <CaretDownOutlined/>
          </div>
          <Tag color="green">
            <div dangerouslySetInnerHTML={{__html: val.to}}></div>
          </Tag>
        </div>
      );
    }
  },
];

const useTableData = (id) => {
  const {isLoading, data} = useRoleLogs({
    user_id: id,
  });
  return {isLoading, data};
};

export function RoleLogsWidget({user}) {
  const {data, isLoading} = useTableData(user?.id);
  const translatedColumns = columns(_t);
  return (
    <>
      <Table
        className="role-log"
        loading={isLoading}
        columns={translatedColumns}
        rowKey="key"
        dataSource={data?.data}
        pagination={false}
        locale={{ emptyText: <NoData /> }}
      />
      <br/>
    </>
  );
}
