import React, {useEffect, useState} from "react";
import {_t} from "../../Common/components/InjectIntlContext";
import Input from "antd/lib/input";
import Button from "../../Common/components/Button";
import SelectInput from "../../Common/components/SelectInput";
import {useProjects} from "../apiHooks";
import Col from "antd/lib/col";
import { Form, Checkbox } from "antd";

type AddOperationFormProps = {
  loading: boolean;
  onFinish: any;
  user: any;
};

const operations = (_t: any) => [
  {
    label: _t("payout"),
    value: "1",
    need_project: "",
  },
  {
    label: _t("fine"),
    value: "2",
    need_project: "1",
  },
  {
    label: _t("prepayment"),
    value: "21",
    need_project: "1",
  },
  {
    label: _t("bonus"),
    value: "3",
    need_project: "1",
  },
];

const AddOperationForm: React.FC<AddOperationFormProps> = ({loading, onFinish, user}) => {
  const translatedOperations = operations(_t);
  const [showPrograms, setShowPrograms] = useState(false);
  const [operationId, setOperationId] = useState();

  const {data: projects, isSuccess: projectsLoadingSuccess} = useProjects({});
  const projectsOptions = projectsLoadingSuccess ? Object.keys(projects).map((keyName) => (
    {
      label: projects[keyName],
      value: keyName,
    }
  )) : undefined;

  useEffect(() => {
    const found = translatedOperations.find(o => (o.value === operationId && o.need_project === "1"));
    setShowPrograms(!!found);
  }, [operationId]);

  const initialValues = {
    amount: 0,
    operation: translatedOperations[0].value,
    visible: 'checked',
  };
  return (
    <Form
      name="add_operation_form"
      onFinish={onFinish} layout="vertical"
      initialValues={initialValues}
      onFieldsChange={(fields) => {
        const operationField = fields.find((f) =>
          (f.name as any)?.includes("operation")
        );
        if (operationField) {
          setOperationId(operationField.value);
        }
      }}
    >
      <Col span="12">
        <Form.Item
          label={_t("amount")}
          name="amount"
          rules={[
            {
              required: true,
              message: _t("input_amount"),
            },
          ]}
        >
          <Input placeholder={_t("amount")} type="number" step="0.01"/>
        </Form.Item>
      </Col>
      <Col span="12">
        <Form.Item
          label={_t("operation")}
          name="operation"
          rules={[
            {
              required: true,
              message: _t("Please input details"),
            },
          ]}
        >
          <SelectInput
            options={translatedOperations}
          />
        </Form.Item>
      </Col>

      <Col span="12" style={{display: showPrograms ? "" : "none"}}>
        <Form.Item
          label={_t("project")}
          name="project_id"
          rules={[
            {
              required: showPrograms,
              message: _t("select_project"),
            },
          ]}
        >
          <SelectInput
            options={projectsOptions}
          />
        </Form.Item>
      </Col>

      <Col span="12">
        <Form.Item label={_t("comment")} name="comment">
          <Input placeholder={_t("comment")}/>
        </Form.Item>
      </Col>

      <Col span="12">
        <Form.Item label={_t("visible")} name="visible" valuePropName="checked">
          <Checkbox></Checkbox>
        </Form.Item>
      </Col>

      <Form.Item className="text-center">
        <Button
          type="primary"
          title={_t("Apply")}
          htmlType="submit"
          disabled={loading}
        />
      </Form.Item>
    </Form>
  );
};

export default AddOperationForm;
