import React from "react";
import LandingTemplate from "../../../Layouts/LandingTemplate";
import Contacts from "./Contacts";

const ContactsPage = () => {
  return (
    <LandingTemplate wrapClass="main-wrap--inner">
      <Contacts />
    </LandingTemplate>
  );
};

export default ContactsPage;
