import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Spin } from "antd";
import * as actionTypes from "../constants";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { notify } from "../../../common/helpers";
import { getUserBasic } from "../../User/actions";

export function ActivateUser({ userData, activateHook }) {
  const dispatch = useDispatch();

  const [activate, { isLoading, isSuccess, error }] = activateHook({
    onSuccess: (res) => {
      dispatch({ type: actionTypes.AUTH_SUCCESS, payload: res.data });
      dispatch(getUserBasic({ user_id: res.data.user_id }));
    },
    onError: (error: any) => {
      notify("error", error.response?.data?.message);
    },
  });

  useEffect(() => {
    if (userData) {
      activate(userData);
    }
  }, [userData]);

  return (
    <div className="h-100per fx-center fxDirCol">
      {!error && userData && (
        <Spin spinning={isLoading}>
          {_t("Authentication in progress, please wait...")}
        </Spin>
      )}
      {isSuccess && <Redirect to={APP_ROUTES.dashboard.general} />}
      {error && !isSuccess && <>{_t("Something went wrong")}</>}
      {!userData && <>{_t("Url is not valid, please contact your admin")}</>}
    </div>
  );
}
