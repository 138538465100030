import React from "react";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Spin } from "antd";

interface MailsProps {
  mailsPath: string;
  useHook: any;
  isAdmin?: boolean;
}

const Mails: React.FC<MailsProps> = ({ mailsPath, useHook, isAdmin }) => {
  const params = isAdmin ? ["inbox", { page: 1 }] : [{ page: 1 }];
  const { data, isLoading } = useHook(...params);
  const newMessages =
    data?.data?.unread_conversations?.length < 100
      ? data?.data?.unread_conversations?.length
      : "99+";

  return (
    <Link to={mailsPath} className="user-mails">
      <MailOutlined />
      <span className="user-mails__amount">{!isLoading && newMessages}</span>
    </Link>
  );
};

export default Mails;
