import React, { useMemo } from "react";
import { ReportTableWidget } from "../components/ReportTable/ReportWidget";
import { DashboardFilters } from "../components/Filters/Filters";
import { Space, Divider, Form } from "antd";
import { TableTotals } from "../components/Totals/TableTotals";
import { CollapsebleChart } from "../components/Charts/LineChart";
import { RadioSelect } from "../../Common/components/RadioSelect";
import { useGeneralReportList, useGeneralReportListFile } from "../apiHooks";
import { useFilters } from "../hooks";
import { addAllOption } from "../utils";
import { allCommissionsColumns } from "../constants";
import { PageTemplate } from "./PageTemplate";
import { ExcelButton } from "../components/Filters/ExcelButton";

const useCommissionsData = () => {
  const [
    { date, page_size, program_id, period, columns, page, groupBy },
  ] = useFilters();

  const scope = groupBy === "day" ? "by-days-programs" : "by-programs";
  const showProgramField = groupBy !== "total";
  const reqFilters = {
    from: date.from,
    to: date.to,
    scope,
    program_id: showProgramField ? program_id : null,
  };
  const { isLoading, data } = useGeneralReportList({
    ...reqFilters,
    page_size,
    page,
  } as any);
  const chartFilters = {
    from: date.from,
    to: date.to,
    period,
    program_id,
    columns,
  };

  const programsOptions = useMemo(
    () =>
      addAllOption(
        data?.programs?.map((p) => ({
          value: p.id,
          label: p.name,
        }))
      ),
    [data?.programs]
  );

  const totals = data?.totals;

  return {
    isLoading,
    programsOptions,
    data,
    totals,
    chartFilters,
    showProgramField,
    reqFilters,
  };
};

export const Commissions = () => {
  const {
    isLoading,
    programsOptions,
    data,
    totals,
    chartFilters,
    showProgramField,
    reqFilters,
  } = useCommissionsData();

  const [getFile, { isLoading: isFileLoading }] = useGeneralReportListFile(
    reqFilters as any
  );
  const clickHandler = () => {
    getFile();
  };

  return (
    <PageTemplate>
      <Space
        size={0}
        direction="vertical"
        style={{ width: "100%", display: "flex" }}
      >
        <DashboardFilters
          fields={
            <>
              {showProgramField && (
                <Form.Item name="program_id">
                  <RadioSelect
                    defaultValue={null}
                    loading={isLoading}
                    allowClear={false}
                    options={programsOptions}
                    dropdownStyle={{ minWidth: 150 }}
                  />
                </Form.Item>
              )}
              <ExcelButton
                clickHandler={clickHandler}
                isLoading={isFileLoading}
              />
            </>
          }
        />
      </Space>

      <TableTotals totals={totals} isLoading={isLoading} />
      <ReportTableWidget
        data={data}
        isLoading={isLoading}
        allColumns={allCommissionsColumns}
        showGroupBy
      />
    </PageTemplate>
  );
};
