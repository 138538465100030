import React from "react";
import { ReactComponent as IconDashboard } from "../../../images/icons/sidebar/dashboard.svg";
import { ReactComponent as IconPromo } from "../../../images/icons/sidebar/promo.svg";
import { ReactComponent as IconTrackers } from "../../../images/icons/sidebar/trackers.svg";
import { ReactComponent as IconPayments } from "../../../images/icons/sidebar/payments.svg";
import { ReactComponent as IconAffiliates } from "../../../images/icons/sidebar/affiliates.svg";
import { ReactComponent as IconCosts } from "../../../images/icons/sidebar/costs.svg";
import { ReactComponent as IconReports } from "../../../images/icons/sidebar/reports.svg";
import { ReactComponent as IconCommission } from "../../../images/icons/sidebar/commission.svg";
import { ReactComponent as IconFaq } from "../../../images/icons/sidebar/faq.svg";
import { ReactComponent as IconContacts } from "../../../images/icons/sidebar/contacts.svg";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { permissions } from "../../../system/constants/roles";

export const NAVIGATION_SCHEME = (_t) => [
  {
    title: _t("Dashboard"),
    path: APP_ROUTES.dashboard.admin,
    icon: <IconDashboard className="icon-custom" />,
    permission: permissions.user_permission.admin,
  },
  {
    title: _t("Dashboard"),
    path: APP_ROUTES.dashboard.general,
    icon: <IconDashboard className="icon-custom" />,
    permission: permissions.user_permission.users,
  },
  {
    title: _t("Promo Materials"),
    path: APP_ROUTES.promo.create,
    icon: <IconPromo className="icon-custom" />,
  },
  {
    title: _t("Payments"),
    path: APP_ROUTES.payments,
    icon: <IconPayments className="icon-custom" />,
  },
  {
    title: _t("Affiliates"),
    path: APP_ROUTES.affiliates,
    icon: <IconAffiliates className="icon-custom" />,
  },
  {
    title: _t("Costs"),
    path: APP_ROUTES.costs.post_back_debug.register,
    icon: <IconCosts className="icon-custom" />,
    permission: permissions.dashboard.header.costs,
  },
  {
    title: _t("Manage"),
    icon: <IconTrackers className="icon-custom" />,
    subMenu: [
      {
        title: _t("Users"),
        path: APP_ROUTES.manage.users,
      },
      {
        title: _t("create_user"),
        path: APP_ROUTES.manage.users_create,
      },
      {
        title: _t("managers"),
        path: APP_ROUTES.manage.managers,
      },
      {
        title: _t("Brands"),
        path: APP_ROUTES.affiliates_programs.brands,
      },
      {
        title: _t("Notifications"),
        path: APP_ROUTES.manage.notifications.list,
      },
    ],
    permission: permissions.manage.users,
  },
  {
    title: _t("reports"),
    icon: <IconReports className="icon-custom" />,
    subMenu: [
      {
        title: _t("affiliates_report"),
        path: APP_ROUTES.reports.affiliates_report,
      },
      {
        title: _t("affiliates_geo_report"),
        path: APP_ROUTES.reports.affiliates_geo_report,
      },
      {
        title: _t("affiliate_difference_report"),
        path: APP_ROUTES.reports.affiliates_difference_report,
      },
      {
        title: _t("gamers_ftd_report"),
        path: APP_ROUTES.reports.gamers_ftd_report,
      },
      {
        title: _t("gamers_report"),
        path: APP_ROUTES.reports.gamers_report,
      },
      {
        title: _t("geo_report"),
        path: APP_ROUTES.reports.geo_report,
      },
    ],
    permission: permissions.reports,
  },
  {
    title: _t("content"),
    icon: <IconTrackers className="icon-custom" />,
    subMenu: [
      {
        title: _t("Faq"),
        path: APP_ROUTES.content.faq.list,
      },
      {
        title: _t("translations"),
        path: APP_ROUTES.content.translations.list,
      },
      {
        title: _t("our_testimonials"),
        path: APP_ROUTES.content.testimonials.list,
      },
    ],
    permission: permissions.content.translation,
  },
];
