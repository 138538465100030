import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";

const NavCard = ({ info, index }) => {
  const { title, icon, link, isOuter } = info;

  return (
    <>
      {isOuter ? (
        <a
          href={link}
          target={isOuter && "_blank"}
          rel="noopener noreferrer"
          className={`nav-card nav-card--${index + 1}`}
        >
          <span className="nav-card__title">{title}</span>
          <span className="nav-card__image">
            <img src={icon} alt="" />
          </span>
        </a>
      ) : (
        <Link to={link} className={`nav-card nav-card--${index + 1}`}>
          <span className="nav-card__title">{title}</span>
          <span className="nav-card__image">
            <img src={icon} alt="" />
          </span>
        </Link>
      )}
    </>
  );
};

export default NavCard;
